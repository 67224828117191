<template>
  <!-- 关于我们 - 关于我们页面 -->
  <div class="about-page w1 fc-text" v-if="!!pageData.logo">
    <!-- 轮播模块 -->
    <div class="banner banner-part">
      <img :src="$IMG_BASE_URL+pageData.banner" alt="" @load="bannerLoadOver = true">
      <div class="banner-text banner-type-1" :class="bannerLoadOver? 'animate': ''">
        <p class="zh"><span>关于我们</span></p>
        <p class="en"><span>ABOUT US</span></p>
      </div>
    </div>
    <!-- 企业简介 -->
    <div class="enterprise-part">
      <div class="w">
        <h3 class="part-title"><span>企业简介</span></h3>
        <div class="part-content-1 flex-c-c">
          <img v-show="pageData.logo" :src="$IMG_BASE_URL+pageData.logo" alt="">
          <div class="text">
            <p class="fc-main fs-24">{{ pageData.title }}</p>
            <p class="fs-16 line-break">{{ pageData.desc }}</p>
          </div>
        </div>
        <!-- 企业模式 -->
        <div class="part-content-2 line-break" >{{ pageData.model }}</div> 
        <!-- higoplat配图展示 -->
        <ul class="part-content-3 flex-c-c fade">
          <li v-for="(item, index) in higoplatArr" :key="index">
            <img :src="item.img" alt="">
            <div class="text">
              <p class="fs-24 fw-bold">{{ item.title }}</p>
              <hr>
              <p class="fs-16">{{ item.content }}</p>
            </div>
          </li>
        </ul>
      </div>
    </div>
    <!-- 愿景部分 -->
    <div class="vision-part">
      <div class="w flex-c-c fade">
        <div class="item" v-for="(item, index) in sloginArr" :key="index">
          <hr>
          <p class="line-1 fc-main mb-15 fs-32">
            <span class="fw-bold">{{ item.title }}  </span>
            <span class="op-02 fw-bold">{{ item.en }}</span>
          </p>
          <p class="line-2 fs-20">{{ item.content }}</p>
        </div>
      </div>
    </div>
    <!-- 企业规划 -->
    <div class="plan-part fade">
      <div class="w">
        <h3 class="part-title"><span>战略规划</span></h3>
        <div class="part-content flex-c-c" v-if="planArr.length > 0">
          <div class="item" v-for="item in planArr" :key="item.title">
            <img :src="item.img" alt="">
            <div class="text fc-white">
              <p class="fs-40 mb-15 fw-bold">{{ item.title }}</p>
              <p class="fs-16 mb-20 fw-bold">{{ item.content }}</p>
              <hr>
            </div>
          </div>
        </div>
      </div>
    </div>
    <!-- 平台差异化 -->
    <div class="diff-part">
      <div class="w fade">
        <h3 class="part-title"><span>平台差异化</span></h3>
        <div class="part-content">
          <div class="part-content-1 fs-16 line-break">
           {{ pageData.diffDesc }}
          </div>
          <div class="part-content-2">
            <img :src="$IMG_BASE_URL+pageData.diffImg" alt="">
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { isElementNotInViewport } from '@/utils/utils'
import api from '@/api'
export default {
  data() {
    return {
      channelId: 3, // 栏目id，用来获取栏目下的内容

      bannerLoadOver: false, // banner图片是否加载完成
      fadeInElements: [], // 淡入元素集合
      pageData: {},  // 页面数据
      higoplatArr: [], // higoplat数据
      sloginArr: [], // slogin数据
      planArr: [], // 战略规划数据
    }
  },
  created() {
    this.getPageData()       
  },
  mounted() {
    // 获取需要加淡入淡出动画的元素
    this.fadeInElements =  Array.prototype.slice.call(document.getElementsByClassName('fade'))

     this.handleScroll()
     // 监听页面滚动
    window.addEventListener('scroll', this.handleScroll)
  },
  methods: {
    // 掉接口 - 获取公司简介栏目下的文章列表（默认只取已审核文章第一条）
    async getPageData() { 
      const { message, contents } = await api.getContentsList({ channelId:this.channelId })
      const index = contents.findIndex(item => item.state === '已审核')
      this.pageData = contents && contents[index] || {}

      // 处理原数据，变成页面需要的格式
      this.formatHigoplat(this.pageData)
      this.formatPlan(this.pageData)
      this.formatSlogin(this.pageData)
    },
    // 格式化higoplat配图数据
    formatHigoplat(obj) {
      const keyArr = ['hi', 'go', 'plat']
      keyArr.map((key, index) => {
        const img = obj[key+'Img'] && this.$IMG_BASE_URL+obj[key+'Img'] || '' // 本地开发需加上imgBaseUrl展示图片
        const title = obj[key+'Title'] || ''
        const content = obj[key+'Content'] || ''

        // 配置了任一内容，都需要展示
        if(img||title||content) this.higoplatArr.push({ img, title, content }) 
      })
    },
    // 格式slogin数据
    formatSlogin(obj) {
      const keyArr = ['slogin1', 'slogin2', 'slogin3']
      keyArr.map((key, index) => {
        const strArr = obj[key] && obj[key].split('\n') || [] // 后台配置时，需要将三部分内容通过回车区分，展示时根据\n分割三部分模块内容
        const title = strArr[0] || ''
        const en = strArr[1] || ''
        const content = strArr[2] || '' 

       // 配置了任一内容，都需要展示
        if(title || en || content) this.sloginArr.push({ title, en, content })
      })
    },
    // 格式化战略规划数据
    formatPlan(obj) {
      const keyArr = ['plan1','plan2','plan3']
      keyArr.map((key, index) => {
        const img = obj[key+'Img'] && this.$IMG_BASE_URL+obj[key+'Img'] || ''
        const title = obj[key+'Title'] || ''
        const content = obj[key+'Content'] || ''
        
        // 配置了任一内容，都需要展示
        if(img||title||content) this.planArr.push({ img, title, content })
      })
    },  
    // 监听页面滚动，通过判断是否在可视区域，显示和隐藏元素
    handleScroll(e) {
      for (var i = 0; i < this.fadeInElements.length; i++) {
        var elem = this.fadeInElements[i]
        if (isElementNotInViewport(elem)) {
          elem.style.opacity = '1'
          elem.style.transform = 'translateY(0)'
        } else {
          elem.style.opacity = '0'
          elem.style.transform = 'translateY(200px)'
        }
      }
    },
  },
  destroyed() {
    document.removeEventListener('scroll', this.handleScroll)
  }
}
</script>
<style lang="less" scoped>
  @import url('~@/styles/m.less');
  @import url('~@/styles/animate.less');
  div{
    box-sizing: border-box;
  }
  .about-page{
    .enterprise-part{
      padding: 75px 0 64px;
      background: url('~@/assets/about/about-desc-bg.png') no-repeat center center/100%;
    }
    .vision-part{
      height: 700px;
      padding-top: 60px;
      background: url('~@/assets/about/about-vision-bg.png') no-repeat center center/100%;
    }
    .plan-part{
      height: 940px;
      padding: 70px 0 20px;
      background: url('~@/assets/about/about-plan-bg.png') no-repeat center center/100%;
    }
    .diff-part{
      padding: 10px 0 75px;
      background: url('~@/assets/about/about-diff-bg.png') no-repeat center center/100%;
    }
  }

  /* banner文字动画 */ 
  .banner-type-1.animate{
    .fade-scale-in(1.1, 1, -50%,-50%, .8s, ease-out, forwards)
  }

  /* 企业文化部分 */ 
  .enterprise-part{
    .part-content-1{
      margin-top: 80px;
      img{ width: 370px;height: 78px; margin-right: 133px;}
      .text{ 
        flex: 1; 
        color: #010101;
        font-family: SourceHanSansCN-Regular;
        p:first-child{ margin-bottom: 28px; }
      }
    }
    .part-content-2{
      margin: 60px 0 74px;
      padding: 45px 50px 52px;
      background: url('~@/assets/about/about-desc-img.png') no-repeat center center/100% 100%;
      color: #fff;
      line-height: 30px;
    }
    .part-content-3{
      justify-content: space-between;
      li{
        width: 31.6%;
        position: relative;
        img{ width: 100%;}
        .text{
          position: absolute;
          bottom: 30px;
          left: 27px;
          hr{ width: 29px; border: 2px solid #000; margin: 16px 0; }
        }
      }
    }
  }

  /* 愿景部分 */ 
  .vision-part{
    .w{ 
      height: 65%;
      flex-direction: column; 
      justify-content: space-between;
      align-items: flex-start;
    }
    .item {
      padding-left: 74px;
      position: relative;
      hr{
          position: absolute;
          border: 1px solid #2559a3;
          width: 32px;
          left: 0;
          top: 15px;
        }
    }
  }

  /* 战略规划部分 */
  .plan-part{
    position: relative;
    .part-content{
      border: 5px solid #FFFFFF;
      box-shadow: 0px 3px 87px 0px rgba(20,48,100,0.2);
      margin-top: 300px;
      .item{ flex: 1; position: relative; }
      .item img{ width: 100%; }
      .item .text{ 
        width: 70%;
        position: absolute; 
        bottom: 30px;
        left: 20px;
        hr{ width: 56px; border: 3px solid #fff; background-color: #fff; margin-left: 0; }
      }
    }
  }

  /* 平台差异化 */ 
  .diff-part{
    .part-content{
      .part-content-1{
        margin: 95px 0 45px;
        padding: 30px 69px 35px;
        background-color: #f7f7f7;
      }
      .part-content-2{
        img{ width: 100%; }
      }
    }
  }

    /* 需要滚动渐入的元素的初始状态 */ 
  .fade{
    opacity: 1;
    // transform: translateY(200px);
    transition: 1s all ease-out;
  }
</style>